import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export const useAuth = () => {
  const { getAccessTokenSilently, user, isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();

  const updateUserMetadata = async (metadata) => {
    const token = await getAccessTokenSilently({
      audience: "https://YOUR_AUTH0_DOMAIN/api/v2/",
      scope: "update:current_user_metadata",
    });

    await axios.patch(
      `https://YOUR_AUTH0_DOMAIN/api/v2/users/${user.sub}`,
      {
        user_metadata: metadata,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return { user, isAuthenticated, loginWithRedirect, logout, updateUserMetadata, isLoading };
};
