import React from 'react';
import { NavLink } from 'react-router-dom';
import { useUser } from '../auth/UserContext';
import Logout from '../auth/Logout';

const Footer = () => {
  const { isAuthenticated } = useUser();
  const baseStyle = 'dark:text-white text-gray-700';
  const activeStyle = 'text-gray-700 dark:text-white dark:border-b-2 dark:border-white border-b-2 border-gray-700';

  return (
    <footer className="fixed bottom-0 w-full shadow-lg z-50 bg-white dark:bg-black border-y border-black">
      <div className="max-w-screen-lg mx-auto px-4 py-2">
        <nav className="flex justify-around">
          <NavLink
            to="/"
            className={({ isActive }) => isActive ? `${activeStyle} py-2` : `${baseStyle} py-2`}
          >
            Home
          </NavLink>
          <NavLink
            to="/user"
            className={({ isActive }) => isActive ? `${activeStyle} py-2` : `${baseStyle} py-2`}
          >
            Profile
          </NavLink>
          {isAuthenticated && <Logout />}
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
