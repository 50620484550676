import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { UserProvider } from './components/auth/UserContext';
import Header from './components/layout/Header';
import Main from './components/layout/Main';

function App() {
  const isLoading = useAuth0.isLoading;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App dark:bg-slate-800 dark:text-white">
      <Header/>
      <UserProvider>
        <Main />
      </UserProvider>
    </div>
  );
}

export default App;
