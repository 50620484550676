import axios from "axios";

const DEBUG = true;
// Create a custom Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
  },
});

const getMission = async (account, id) => {
  if (DEBUG) {
    console.log("Mission requested");
  }
  const response = await axiosInstance.get(
    `/missions/${id}?account=${account}&availability=true&sort=priority`
  );
  return response.data;
};

const getMissions = async (account) => {
  if (DEBUG) {
    console.log("Missions requested");
  }
  const response = await axiosInstance.get(`/missions?account=${account}`);
  return response.data;
};

const getPlayerMission = async (account, player, missionId) => {
  if (DEBUG) {
    console.log("Player mission requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}/missions/${missionId}/?account=${account}&availability=true`
  );
  return response.data;
};

const getPlayerMissions = async (account, player) => {
  if (DEBUG) {
    console.log("Player missions requested");
  }
  const response = await axiosInstance.get(
    `/players/${player}/missions/?account=${account}&availability=true`
  );
  return response.data;
};

export {
  getMission,
  getMissions,
  getPlayerMission,
  getPlayerMissions,
};