import React from 'react';
import Banner from '../common/Banner';

const UserProfile = ({darkMode}) => {
  return (
    <>
      <Banner
        title='Your account details'
        description="Will appear here when we make it work..."
        darkMode={darkMode}
      ></Banner>
    </>
  );
};

export default UserProfile;