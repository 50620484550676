import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import MyJourney from '../pages/MyJourney';
import UserProfile from '../pages/Profile';
import Footer from './Footer';

const Main = () => {
  return (
    <main className="flex flex-col grow mt-20 w-full min-h-screen dark:bg-gray-600 dark:text-white">
      <Router>
        <div className='max-w-screen-lg w-full grow self-center'>
          <Routes>
            <Route
              path="/user"
              element={<UserProfile />}
            />
            <Route
              path="/my-journey"
              element={<MyJourney />}
            />
            <Route
              path="/"
              element={<HomePage />}
            />
          </Routes>
        </div>
        <Footer />
      </Router>
    </main>
  );
};

export default Main;
