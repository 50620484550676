import React from 'react';
import Banner from '../common/Banner';
import MissionList from '../lists/MissionsList';
import { NavLink } from 'react-router-dom';
import TopNav from '../layout/TopNav';

const HomePage = () => {

  return (
    <div>
      <Banner
        description={'Earn additional loyalty points and unlock new rewards in your loyalty programme by completing the quests'}
      />
      <TopNav/>
      <MissionList className="mt-6" />
    </div>
  );
};

export default HomePage;