import React from 'react';
import Banner from '../common/Banner';
import TopNav from '../layout/TopNav';

const MyJourney = () => {
  return (
    <div>
      <Banner
        title='My Journey'
        description="Here you'll see your journey"
      ></Banner>
      <TopNav />
    </div>
  );
};

export default MyJourney;