import React, { useEffect, useState } from 'react';
import Mission from '../../entities/Mission';
import Card from '../common/Card';
import { getMissions } from '../../services/missions';

const MissionList = () => {
  const glAccount = process.env.REACT_APP_ACCOUNT;
  const player = process.env.REACT_APP_DEMO_PLAYER;
  const [missions, setMissions] = useState([]);
  const [missionProgress, setMissionProgress] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMissions = async () => {
      try {
        const missionsData = await getMissions(glAccount);
        const missionObjects = missionsData.map(mission => new Mission(mission.id, mission.name, mission.description, mission.imgUrl, mission.reward.points));
        
        // Fetch and update data for all missions
        await Promise.all(missionObjects.map(mission => mission.updateData()));
        
        setMissions(missionObjects);

        // Fetch progress for all missions
        const progressData = await Promise.all(missionObjects.map(async (mission) => {
          const progress = await mission.getProgress();
          return { id: mission.id, progress };
        }));

        // Create a mapping of mission ID to progress
        const progressMapping = progressData.reduce((acc, curr) => {
          acc[curr.id] = curr.progress;
          return acc;
        }, {});

        setMissionProgress(progressMapping);

      } catch (error) {
        setError(`Failed to retrieve missions. Please try again. Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchMissions();
  }, [glAccount, player]);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    console.log("Error:", error);
    return <div className="text-center">Error: {error}</div>;
  }

  return (
    <div className="space-y-4 mb-16">
      {missions.map((mission, index) => (
        <Card
          key={mission.id}
          imageSrc={mission.imgUrl}
          title={mission.name}
          description={mission.description}
          points={mission.reward}
          progress={missionProgress[mission.id] || 0}
          clickHandler={() => mission.handleClick()}
          index={index}
        />
      ))}
    </div>
  );
};

export default MissionList;
