// UserContext.js
import React, { createContext, useContext } from 'react';
import { useAuth } from './useAuth';
import Login from './Login';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const auth = useAuth();

  if (auth.isLoading) {
    return <div>Loading...</div>; // or a custom loading component
  }

  return (
    <UserContext.Provider value={auth}>
      {auth.isAuthenticated ? children : <Login />}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
