import React from 'react';
import { useUser } from './UserContext';

const Logout = () => {
  const { logout, isAuthenticated } = useUser();

  if (!isAuthenticated) {
    return <p>You are already logged out!</p>;
  }

  return (
    <button 
      onClick={() => logout({ returnTo: window.location.origin })} 
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
    >
      Log Out
    </button>
  );
};

export default Logout;