import axios from "axios";

const DEBUG = true;
// Create a custom Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": process.env.REACT_APP_API_KEY,
  },
});

const getEvents = async (account) => {
  if (DEBUG) {
    console.log("Events requested");
  }
  const response = await axiosInstance.get(`/events?account=${account}`);
  return response.data;
};

const getEvent = async (account, id) => {
  if (DEBUG) {
    console.log("Event requested");
  }
  const response = await axiosInstance.get(`/events/${id}?account=${account}`);
  return response.data;
};

const completeEvent = async (account, player, event, count = 1) => {
  try {
    const response = await axiosInstance.post(`/events/${event}/complete`, {
      account,
      player,
      count,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export {
  getEvent,
  getEvents,
  completeEvent,
};