import React from 'react';
import bannerImage from '../../assets/crate-icon.svg';

const Banner = ({ title, description }) => {
  return (
    <div className="flex gap-6 p-6 dark:bg-gray-900 bg-[#1555F5] text-white items-center mr-12 min-h-60">
      <div className='flex flex-col'>
        <h2 className="text-3xl font-bold">{title}</h2>
        <p className="mt-2 text-lg">{description}</p>
      </div>
      <img src={bannerImage} alt='Banner Image' className='ml-auto w-auto h-auto mt-4 rounded-lg max-h-30 -mr-11' />
    </div>
  );
};

export default Banner;