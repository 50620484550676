import { getPlayerMission } from "../services/missions";
import { completeEvent } from "../services/events";

class Mission {
  constructor(id, name, description, imgUrl, reward) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.imgUrl = imgUrl;
    this.reward = reward;
    this.progress = 0;
    this.drivingEvents = [];
    this.available = false;
    this.updateData();
  }

  async updateData() {
    const glAccount = process.env.REACT_APP_ACCOUNT;
    const player = process.env.REACT_APP_DEMO_PLAYER;

    try {
      const missionData = await getPlayerMission(glAccount, player, this.id);
      this.available = missionData.progress.state === 'started' || missionData.progress.state === 'available';
      this.progress = (missionData.progress.totalAverage ?? 0) * 100;
      this.drivingEvents = missionData.progress.events;
      return this.available;
    } catch (error) {
      console.error(`Failed to get progress for mission ${this.id}:`, error);
      return false;
    }
  }

  async getProgress() {
    return this.progress;
  }

  async handleClick() {
    const glAccount = process.env.REACT_APP_ACCOUNT;
    const player = process.env.REACT_APP_DEMO_PLAYER;
    const id = this.drivingEvents[0].id;
    try {
      completeEvent(
        glAccount,
        player,
        id,
        1
      ).then(
        (response) => {
          if (response === 200) {
            console.log("Event completed successfully");
          }
        }
      );

    } catch (error) {
      console.error("Error completing the event:", error);
    }
  }
}

export default Mission;
