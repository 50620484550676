import React from 'react';
import { useUser } from './UserContext';

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useUser();

  if (isAuthenticated) {
    return <p>You are already logged in!</p>;
  }

  return (
    <div className='flex flex-col min-h-screen grow justify-center items-center'>
      <div className='flex space-around min-w-80 space-x-8 m-12 items-center justify-center'>
         <h1 className='text-2xl'>Please, login first</h1>
      </div>
      <button 
        onClick={loginWithRedirect} 
        className="bg-[#1555F5] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Log in
      </button>
    </div>
  );
};

export default Login;