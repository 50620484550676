import React from 'react';
import { NavLink } from 'react-router-dom';

const TopNav = () => {
  const baseStyle = 'dark:text-white text-gray-700';
  const activeStyle = 'text-[#1555F5] dark:text-white dark:border-b-2 dark:border-white border-b-2 border-[#1555F5]';

  return (
    <nav className="flex justify-around p-4 w-full shadow-lg z-50 bg-white dark:bg-black mb-4 rounded-b-lg">
      <NavLink
        to="/"
        className={({ isActive }) => isActive ? `${activeStyle} py-2` : `${baseStyle} py-2`}
      >
        Quests
      </NavLink>
      <NavLink
        to="/my-journey"
        className={({ isActive }) => isActive ? `${activeStyle} py-2` : `${baseStyle} py-2`}
      >
        My Journey
      </NavLink>
    </nav>
  );
};

export default TopNav;