import React from "react";
import glLogo from "../../assets/Logo.png";
import lpLogo from "../../assets/logo2.png";
import DarkModeToggle from "./DarkModeToggle";

const Header = () => {
  return (
    <header className="flex fixed top-0 w-full bg-white  text-gray-800  dark:bg-gray-900 dark:text-white shadow-md border-b">
      <div className="flex max-w-screen-lg w-full items-center mx-auto self-center justify-between p-5 space-x-4">
        <div className="flex items-center gap-2">
          <img src={lpLogo} alt="Logo 1" className="h-10 w-auto" />
          <div className="hidden md:block font-semibold text-xl">
            LoyaltyPlays
          </div>
        </div>
        <div className="flex items-center gap-2">
          <img src={glLogo} alt="Logo 2" className="h-10 w-auto" />
          <div className="hidden md:block font-semibold text-xl">
            GameLayer
          </div>
        </div>
        <DarkModeToggle />
      </div>
    </header>
  );
};

export default Header;
