import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';

const Card = ({ imageSrc, title, description, progress, points, clickHandler, index }) => {
  const bgColors = ["bg-[#1F66FB] text-white", "bg-[#FFC107] text-black", "bg-[#FF4B4B] text-white", "bg-[#43DBA5] text-white"];
  const progressColors = ["bg-[#FFC107]", "bg-[#FF4B4B]", "bg-[#43DBA5]"];

  return (
    <div className={`${bgColors[index % bgColors.length]} m-6 text-slate-900 dark:bg-slate-800 dark:text-white shadow-md rounded-lg p-4 flex flex-col items-center justify-center max-w-400`}>
      <div className="w-full flex items-center gap-4">
        <img src={imageSrc} alt="Card Image" className="w-auto h-auto mt-4 rounded-lg max-h-12 max-w-12" />
        <div className="flex flex-col flex-grow">
          <div className="flex w-full items-center">
            <h2 className="text-l font-bold mb-2">{title}</h2>
            <button className="flex mr-0 flex-col bg-[#EAEFFEE5] ml-auto dark:bg-slate-700 hover:bg-white text-black dark:text-white font-bold py-1 px-4 items-center rounded-full" onClick={clickHandler}>
              <p className="text-xs">{points}</p>
              <p className="text-xs">Points</p>
            </button>
          </div>
          <div className="flex flex-col">
            <p className="dark:text-white">{description}</p>
            <div className="w-full h-5 mt-5 bg-gray-200 rounded-full dark:bg-gray-700">
              <div className={`${ progress != 0 ? progressColors[index % progressColors.length] : ''} dark:bg-slate-900 h-5 text-xs rounded-full font-medium text-white text-center p-0.5 leading-none rounded-full min-w-4`} style={{ width: progress+"%"}}>{progress + "%"}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Card;