import React, { useEffect, useState, setState } from 'react';
import { SunIcon } from '@heroicons/react/solid';
import { MoonIcon } from '@heroicons/react/outline';

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  }

  useEffect(() => {
    const initDarkMode = () => {
      const html = document.querySelector('html');
      if (darkMode) {
        html.classList.add('dark');
        localStorage.setItem('darkMode', 'enabled');
      } else {
        html.classList.remove('dark');
        html.removeAttribute('style');
        localStorage.setItem('darkMode', 'disabled');
      }
    };
    initDarkMode();
  }, [darkMode]);

  return (
    <button
      onClick={toggleDarkMode}
      className="p-2 rounded-full focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 dark:bg-gray-800 bg-gray-600 dark:text-white text-gray-800"
    >
      {
        darkMode ? 
        <SunIcon className="w-6 h-6 text-yellow-500" aria-label="Sun Icon" />
        :
        <MoonIcon className="w-6 h-6 text-yellow-300" aria-label="Moon Icon" />
      }
    </button>
  );
};

export default DarkModeToggle;
